.rsSplashPage {
	transform: translate3d(0, 0, 0);
	background-image: url("./davies_house_vertical.jpg");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;


	.fullScreenDim {
		position: absolute;
		width: 100%;
		top: 0;
		bottom: 0;
		z-index: -1;
		background-color: rgba(0,0,0,.3);
	}

	.daviesLogo {
		width: calc(80% - 40px);
		margin: auto;
		position: absolute;
		bottom: 24px;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: column;

		img {
			max-width: 100%;
			max-height: 100%;
		}

		.rsLabel {
			margin-top: 35px;
		}
	}
}