#rsContactUsPopup {
	.popupFrame {
		width: 302px;
		height: 430px;
		background-color: white;
		border-radius: 15px;
		position: relative;
		padding-top: 43px;
	}

	.closeBtn {
		position: absolute;
		right: 15px;
		top: 15px;
	}

	.submitBtn {
		margin: 0 72px;
		height: 55px;
		border-radius: 4px;
		background-color: #29455F;
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.contactForm {
		background-color: #F2F2F2;
		padding: 32px 37px;
		color: #8A8A8F;
		margin-top: 18px;
		margin-bottom: 24px;

		.rsInput input {
			background-color: #F2F2F2;
			font-size: 13px;
			height: unset;
			border-bottom: 1px solid #DEDCDC;
			width: 100%;
			margin-bottom: 25px;
			padding-left: 0;
			outline: none;

			&::placeholder {
				padding: 0;
			}
		}

		.rsInput textarea {
			background-color: #f2f2f2;
			border: none;
			border-bottom: 1px solid #DEDCDC;
			outline: none;
			width: 100%;
			font-size: 13px;
			resize: none;
			height: 70px;

			&::placeholder {
				padding: 0;
			}
		}
	}

	.companyLogo {
		margin-top: 15px;
	}
}