.productCard {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 151px;
  height: 201px;
  padding: 12px;
  margin: 9px 12px;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px -3px rgba(0,0,0, .5);

    img {
      width: 126px;
      height: 126px;
      object-fit: contain;
      margin: 0 auto;
    }

    .productName{
      margin-top: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      height: 18px;
    }

    .productPrice {
      margin-top: 5px;
    }

  &:hover {
    cursor: pointer;
  }
}