.houseCard {
	width: 100%;
	height: 225px;
	position: relative;

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 214px;
		position: absolute;
	}

	.banner {
		background-color: #29455f;
		color: white;
		display: flex;
		position: absolute;
		right: 0;
		left: 27px;
		height: 37px;
		bottom: -7px;
		justify-content: space-between;
		align-items: center;
		padding: 5px 15px;
		box-shadow: 0 2px 8px 0 #636363;
		cursor: pointer;
	}

	.rightArrow {
		vertical-align: middle;
		font-size: 15px;
	}
}
