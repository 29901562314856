@import 'variables';
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
	height: 100% !important;
}

body {
	margin: 0;
	height: 100%;
	overflow: hidden;

	font-family: 'Rubik', sans-serif;

	color: $primaryTextColor;
}

@media screen and (min-device-width: 800px) {
	body {
		max-width: $content-width;
		width: $content-width;
		margin: auto;
	}
}



#App {
	position: relative;
	.rs-tab {
		color: $gray;
		.active-tab {
			color: $black;
		}
	}
}

@media not all and (hover: none) {
	// Hide scroll bars. They look ugly in windows
	::-webkit-scrollbar {
		//display: none;
		width: 5px;
		height: 8px;
		background-color: #aaa; /* or add it to the track */
	}

	::-webkit-scrollbar-thumb {
		background: #525252;
	}
}



.react-icons {
	vertical-align: middle;
}

// Not sure why we have this in our framework
.rs-page {
	height: calc(100VH - 50px) !important;
	overflow-y: scroll !important;
}

@media only screen and (max-width: 813px) {
	.rs-page, .rs-view {
		height: 100% !important;
	}
}

input, textarea {
	font-family: 'Rubik', sans-serif;
}

.rsErrorMessage {
	font-size: 12px;
	margin-top: -22px;
	margin-bottom: 10px;
}


.snapScrollParent {
	display: flex;
	overflow: auto;
	overflow-x: auto;
	overflow-y: hidden;
	-webkit-overflow-scrolling: touch;
	-webkit-scroll-snap-type: mandatory;
	scroll-snap-type: x mandatory;
	-webkit-scroll-snap-points-x: repeat(100%);
	scroll-snap-points-x: repeat(100%);
}

.snapScrollChild {
	overflow: visible;
	scroll-snap-align: center;
}
