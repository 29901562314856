.rsShopAllPage {
	position: relative;

	.pageHeader {
		position: absolute;
		display: flex;
		align-items: center;
		left: 22px;
		top: 15px;
		color: #8a8a8f;

		&:hover {
			cursor: pointer;
		}
	}

	#AllProductsContainer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin-top: 80px;
	}
}
