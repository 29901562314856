@import '../../variables';

.rsInput {
	.alignLabelLeft {
		width: $content-width;
		text-align: left;
		text-transform: uppercase;
		margin-bottom: 2px;

		.inputLabel {
			color: $textGray;
			font-size: 13px;
			font-weight: 600;
		}
	}

	input {
		height: 40px;
		font-size: 20px;
		border: none;
	}

	.errorMessage {
		width: 250px;
	}

	::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: lightgrey;
		padding-left: 10px;
	}

	::-moz-placeholder {
		/* Firefox 19+ */
		color: lightgrey;
		padding-left: 10px;
	}

	:-ms-input-placeholder {
		/* IE 10+ */
		color: lightgrey;
		padding-left: 10px;
	}

	:-moz-placeholder {
		/* Firefox 18- */
		color: lightgrey;
		padding-left: 10px;
	}
}
