#rsFloorPlanPopup {
	.popupFrame {
		width: 100%;
		height: 100%;
		background-color: white;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 65px 0;
		overflow-y: scroll;
		box-sizing: border-box;
	}

	.backBtn {
		position: absolute;
		left: 20px;
		top: 20px;
		display: flex;
		align-items: center;
		font-weight: 500;

		& > * {
			padding-right: 5px;
		}

	}

	img {
		max-width: 70%;
		margin-top: 5px;
		margin-bottom: 50px;
	}
}
