@import '../../variables.scss';

.rsHomeDetailsPage {
	.homeOverlay {
		width: 375px !important;
		height: 1398px !important;
	}

	.detailsHeaderImage {
		max-height: 250px;
		width: 100%;
		object-fit: cover;
	}

	.backButton {
		position: absolute;
		color: white;
		font-size: 28px;
		left: 22px;
		top: 15px;
		cursor: pointer;
	}

	.inset {
		position: relative;
		margin: 0 22px;
		border-radius: 7px;
		box-shadow: 0 0 4px 0 #696969;
		padding: 18px 33px 25px 33px;
		top: -85px;
		background: white;
	}

	.houseStats {
		display: flex;
		justify-content: space-between;
		margin: 20px;
		padding: 0 10px;
		align-items: center;
	}

	.houseStats > div {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.houseStats > div > img {
		margin-bottom: 12px;
	}

	.selector {
		background-color: #e1e1e1;
		margin-bottom: 15px;
		display: flex;
		padding: 19px 20px;
		align-items: center;
		justify-content: space-between;
		position: relative;
		width: calc(100% + 55px);
		box-sizing: border-box;
		cursor: pointer;

		svg {
			font-size: 28px;
			vertical-align: middle;
			position: absolute;
			right: 11px;
		}
	}

	.selector.blue {
		background-color: $blue;
		margin-bottom: 35px;

		svg {
			color: white;
		}
	}

	.about {
		margin-bottom: 5px;
	}

	video.houseVideoOverview {
		width: 100%;
		margin: 20px 0 50px 0;
		border-radius: 10px;
		min-height: 300px;
	}

	.floorPlan {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 50px;
		border-bottom: 1px solid #E7E7E7;
		margin-bottom: 50px;
		color: $blue;

		&:hover {
			cursor: pointer;
		}
	}

	.floorPlan img {
		width: 46px;
	}

	.location {
		display: flex;
		align-items: center;
		justify-content: space-between;

		svg {
			vertical-align: middle;
		}

		.locationMarkerIcon {
			font-size: 50px;
		}

		.nextIcon {
			font-size: 35px;
			color: #8A8A8F;
			background-color: #F0F0F0;
			border-radius: 35px;

			&:hover {
				cursor: pointer;
			}
		}
	}

	.daviesLogo {
		margin: -50px auto 30px;
		display: flex;
		flex-direction: column;
		align-items: center;

		& > * {
			padding-bottom: 5px;
		}

		img {
			width: 100px;
			height: 100%;
		}

		.learnMoreLink {
			color: $blue;
		}
	}
}
