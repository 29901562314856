.rsExteriorDetailsPage {
	.content {
		padding-left: 35px;
		padding-right: 35px;
		margin-bottom: 35px;
	}

	.backButton {
		position: absolute;
		color: white;
		font-size: 28px;
		left: 22px;
		top: 15px;
		cursor: pointer;
		z-index: 50;
	}

	.exteriorTitle {
		margin-top: 0;
		margin-bottom: 18px;
	}

	.exteriorSlider {
		img {
			height: 300px;
			object-fit: cover;
		}
	}

	.sliderImg {
		width: 600px;
		height: 300px;
		display: block;
		object-fit: cover;
	}

	@media only screen and (max-width: 813px) {
		.sliderImg {
			width: 375px;
		}
	}
}
