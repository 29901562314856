@import "../../variables.scss";

.rsItemDetailsPage {

	.content {
		padding: 40px 40px;
	}

	.price {
		margin-bottom: 25px;
	}

	.addToCart {
		display: flex;
		margin-bottom: 42px;
		align-items: center;
		justify-content: space-between;

		.flexCenter {
			display: flex;
			align-items: center;
			position: relative;
		}

		.addToCartButton {
			height: 55px;
			width: 230px;
			background-color: $blue;
			display: flex;
			justify-content: center;
			align-items: center;
			color: white;
			border-radius: 5px;

			&:hover {
				cursor: pointer;
			}
		}

		.quantity {
			color: $gray;

			.downArrow {
				position: absolute;
				right: -3px;
			}

			select {
				//width: 40px;
				width: 100%;
				font-size: 20px;
				border: none;
				-webkit-appearance: none;

				&:focus {
					outline: none;
				}

				&:hover {
					cursor: pointer;
				}
			}

		}
	}


	.backButton {
		position: absolute;
		color: $lightGray;
		font-size: 28px;
		left: 22px;
		top: 15px;
		cursor: pointer;
		z-index: 1;
	}

	.itemSlider {
		img {
			height: 300px;
			object-fit: contain;
		}
	}
}
