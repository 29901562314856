.rsCartPage {



  #CartItemWrapper{
    height: calc(100% - 205px);
    overflow: auto;

    .emptyCart {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .cartItem {
      display: flex;
      justify-content: start;
      align-items: center;
      position: relative;
      height: 100px;
      width: 306px;
      margin: 0 auto;
      color: #878787;
      border-bottom: 2px solid #E7E7E7;

      img {
        width: 83px;
        height: 83px;
        object-fit: contain;

        &:hover {
          cursor: pointer;
        }
      }

      h3 {
        font-size: 16px;
        font-weight: 500;
        margin: 0;
      }

      .itemDetails {
        margin-left: 20px;

        .itemPrice {
          font-size: 14px;
          font-weight: 400;
        }

        .itemQty {
          font-size: 12px;
          margin-top: 15px;
        }
      }

      .deleteBtn {
        font-size: 12px;
        font-weight: 400;
        position: absolute;
        color: #FC7C7C;
        right: 10px;
        bottom: 15px;

        &:hover {
          cursor: pointer;
        }
      }


    }
  }

  #CartTotalSectionWrapper {
    position: absolute;
    bottom: 0;
    background-color: #F2F2F2;
    //height: 162px;
    height: 145px;
    width: 100%;
    padding: 17px 0;
    box-sizing: border-box;


    #CartTotalSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 306px;
      margin: 0 auto;

      .taxes, .subTotal{
        font-size: 14px;
        color: #878787;
        font-weight: 400;
        margin-bottom: 5px;
      }

      .total {
        font-size: 20px;
        font-weight: 500;
        color: #404044;
        margin-top: 8px;
      }

      #CheckOutBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        color: #ffffff;
        width: 306px;
        height: 40px;
        border-radius: 4px;
        background-color: #29455F;

        &:hover {
          cursor: pointer;
        }
      }

      .dividingLine {
        width: 324px;
        height: 1px;
        background-color: #D6D6D6;
      }

      > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
    }
  }


}