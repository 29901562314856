/* App color scheme */
$blue: #24435f;
$gray: #404044;
$lightGray: #6D6D72;
$black: #3e3e42;
$white: #fff;

/* Used inside of label */
$textGray: $gray;
$textLightGray: $lightGray;
$textBlue: $blue;


/* Global */
$primaryTextColor: $black;
$primaryButtonTextColor: $white;
$primaryButtonBackgroundColor: $blue;
$content-width : 600px;