@import "../../variables.scss";

.companyLogo {
	display: flex;
	flex-direction: column;
	align-items: center;

	& > * {
		padding-bottom: 5px;
	}

	img {
		width: 100px;
		height: 100%;
	}

	.learnMoreLink {
		color: $blue;
	}
}
