@import "../../variables.scss";

.rsBrowseRoomsPage {
	position: relative;
	//.backButton {
	//	position: absolute;
	//	color: #8a8a8f;
	//	font-size: 28px;
	//	left: 22px;
	//	top: 15px;
	//	cursor: pointer;
	//}

	.navTitle {
		display: flex;
		align-items: center;
		color: #8a8a8f;
		position: absolute;
		left: 50px;
		top: 18px;

		&:hover {
			cursor: pointer;
		}
	}

	.floorPlan {
		display: flex;
		justify-content: center;
		align-items: center;
		color: #29455F;
		border: 1px solid #29455F;
		border-radius: 4px;
		padding: 0 10px;
		position: absolute;
		right: 14px;
		width: 123px;
		height: 36px;
		top: 12px;
	}

	.floorPlan img {
		width: 35px;
	}

	.cardWrapper {
		width: calc(100% - 40px);
		min-width: calc(100% - 40px);
		position: relative;
		padding-right: 15px;

		.exploreButton {
			position: absolute;
			bottom: 70px;
			left: 0;
			width: 80%;
			color: #252525;
			background-color: rgba(255, 255, 255, 0.62);
			padding: 13px;
			font-size: 16px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-weight: 400;
		}

		//.roomName {
		//	position: absolute;
		//	top: -10px;
		//	left: 10px;
		//}
	}

	.roomList {
		margin-top: 100px;
		padding-left: 15px;
		display: flex;
		height: 550px;
		width: calc(100% - 15px);
		overflow-x: scroll;

		img {
			object-fit: cover;
			width: 100%;
			height: calc(100% - 50px);
			border-radius: 5px;
		}
	}
}
