#rsEmptyCartPopup {

  #popUpFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 300px;
    height: 150px;
    padding: 10px;
    border-radius: 4px;
    position: relative;

    .closeBtn {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }
}
