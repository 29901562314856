//@import '../../style/text.scss';
@import "../../variables.scss";

.rsLabel {
	&.allCaps {
		text-transform: uppercase;
	}

	&.left {
		text-align: left;
	}

	&.right {
		text-align: right;
	}

	&.center {
		text-align: center;
	}

	&.tiny {
		font-size: 7.5px;
	}

	&.xxSmall {
		font-size: 10px;
	}

	&.xSmall {
		font-size: 13px;
	}

	&.small {
		font-size: 15px;
	}

	&.medSmall {
		font-size: 18px;
	}

	&.medium {
		font-size: 20px;
	}

	&.medLarge {
		font-size: 22px;
	}

	&.large {
		font-size: 25px;
	}

	&.xLarge {
		font-size: 30px;
	}

	&.xxLarge {
		font-size: 35px;
	}

	&.fw100 {
		font-weight: 100;
	}

	&.fw300 {
		font-weight: 300;
	}

	&.fw500 {
		font-weight: 500;
	}

	&.fw700 {
		font-weight: 700;
	}

	&.fw900 {
		font-weight: 900;
	}

	&.white {
		color: white;
	}

	&.blue {
		color: $textBlue;
	}

	&.black {
		color: black;
	}

	&.red {
		color: red;
	}

	&.gray {
		color: $textGray;
	}

	&.lightGray {
		color: $textLightGray;
	}
}
