@import '../../variables.scss';

.rsHomePage {
	.daviesLogo {
		width: 101px;
		margin: 45px auto 11px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.divider {
		background-color: #d8d8d8;
		height: 3px;
		width: 45px;
		margin: 4px auto 15px;
	}

	.houseCard {
		margin-bottom: 50px;
	}
}
