@import '../../variables.scss';

.rs-tabs {
	background: #ffffff;
	justify-content: space-around;
	position: fixed;
	max-width: $content-width;

	.rs-tab {
		margin: auto 0;
	}

	.rs-tab svg {
		height: 27px;
		width: 33px;
	}
}
