@import '../../variables.scss';

.rsRoomDetailsPage {
	.navHeader {
		display: flex;
		align-items: center;
		position: absolute;
		color: white;
		z-index: 50;
		left: 20px;
		top: 12px;
		font-size: 18px;

		svg {
			margin-right: 7px;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.sliderImg {
		height: 100%;
		object-fit: cover;
	}

	.content {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 0 23px;
		margin-top: 25px;

		video {
			margin-top: 30px;
			margin-bottom: 25px;
			width: 100%;
			background-color: #a2a2a5;
			border-radius: 5px;
		}

		.designTeamHeader {
			font-style: italic;
			margin-bottom: 24px;
		}
	}

	.productView {
		position: fixed;
		top: 0;
		bottom: 50px;
		width: 100%;
		max-width: $content-width;
		background: rgba(0, 0, 0, 0.38);

		.productCarousel {
			position: absolute;
			bottom: 0;
			height: 235px;
			width: 100%;
			background-color: white;

			.header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 10px 20px;
			}

			.productContainer {
				width: calc(100% - 0px);
				box-sizing: border-box;
				padding: 5px 20px;
				overflow-x: auto;
				display: flex;
				-webkit-overflow-scrolling: touch;

				&::after {
					content: '';
					width: 5px;
					min-width: 5px;
				}

				.productCard {
					width: 125px;
					height: 158px;
					img {
						max-width: 95px;
					}
				}
			}
		}
	}

	.shopThisRoom {
		height: 65px;
		background-color: #29455f;
		display: flex;
		align-items: center;
		color: white;
		padding-left: 25px;
		font-size: 16px;
		font-weight: 300;

		svg {
			padding-right: 9px;
		}

		.upArrow {
			position: absolute;
			right: 20px;
		}
	}

	.designerCallout {
		width: 100%;
		display: flex;
		background-color: #f2f2f2;
		height: 87px;
		align-items: center;
		justify-content: center;
		margin-top: 20px;

		.avatarPhoto {
			margin-right: 10px;
			img {
				width: 40px;
				max-width: 40px;
				border-radius: 20px;
				margin-bottom: -6px;
			}
		}

		.designerLabel {
			letter-spacing: 3px;
		}
	}

	.daviesLogo {
		margin: 10px auto 30px;
		display: flex;
		flex-direction: column;
		align-items: center;

		& > * {
			padding-bottom: 5px;
		}

		img {
			width: 100px;
			height: 100%;
		}

		.learnMoreLink {
			color: $blue;
		}
	}
}
